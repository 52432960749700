import React, { useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

/* --- Local Imports --- */
import { PageContent } from '../components/common/LayoutComponents';
import { SectionTitle, Text } from '../components/common/TextComponents';
import { projects_data, ProjectType, ProjectTypes } from '../context/ProjectsContext';
import ProjectCard, { CardHeader, CardHeaderImg, projectImg } from '../components/complex/ProjectCard';
import ResponsiveModal from '../layouts/ResponsiveModal';

const HorizontalDiv = styled('div')(({theme}) => ({
    display: 'flex',
    overflowX: 'scroll',
    flex: '0 0 auto',
    padding: theme.spacing(2, 2, 4),
    '&::-webkit-scrollbar': {
        display: 'none'
    }
}));

const QRImage = styled('img')(({theme}) => ({
    width: '150px',
    alignSelf: 'center',
    marginTop: theme.spacing(2)
}));

const ProjectsPage = () => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState<ProjectType | null | undefined>(null);

    const onViewProject = (project: ProjectType) => {
        console.log(project.type);
        if (project.type === ProjectTypes.Web) {
            window.open(project.url, '_blank');
        }
        if (project.type === ProjectTypes.Mobile) {
            setModalContent(project);
            setOpenModal(true);
        }
    };

    const onHandleClose = () => {
        setOpenModal(false);
        setModalContent(null);
    };

    const instrunctions = (
        <div>
            <Text sx={{my: 1}}><b>1.</b> Download the <a href="https://expo.io/client" rel="noreferrer" target="_blank">Expo Client App</a> to demo apps without having to install them on your device</Text>
            <Text sx={{my: 1}}><b>2.</b> Scan the QR Code below from the Expo App</Text>
        </div>
    );

    const projectModal = (
        <ResponsiveModal open={openModal} onClose={onHandleClose}>
            <CardHeader sx={{mb: 2}}>
                <Text sx={{fontWeight: 'bold'}}>{modalContent?.title}</Text>
                <CardHeaderImg src={(projectImg(modalContent?.type || ''))} alt={modalContent?.type}/>
            </CardHeader>
            {instrunctions}
            {modalContent && <QRImage src={(modalContent.qr_img || '')} alt={modalContent.title}/>}
        </ResponsiveModal>
    );

    return (
        <PageContent sx={{pb: 10, pt: {sm: 0, xs: 3}}}>
            {projectModal}
            <SectionTitle>Personal Projects</SectionTitle>
            {!mobile ? <Grid container spacing={3} sx={{marginTop: 0, marginBottom: 4}}>
                {projects_data.map((e, i) => (
                    <Grid item key={i} lg={4} md={6} sm={6} xs={12}>
                        <ProjectCard project={e} onView={() => onViewProject(e)} />
                    </Grid>
                ))}
            </Grid>: 
            <HorizontalDiv>
                {projects_data.map((e, i) => (
                    <ProjectCard key={i} project={e} onView={() => onViewProject(e)}/>
                ))}
            </HorizontalDiv>}
        </PageContent>
    );
};

export default ProjectsPage;