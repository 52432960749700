import React from 'react';
import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

/* --- Local Assets --- */
import {ReactComponent as AboutSvg} from '../assets/svg/about_illustration.svg';

/* --- Local Imports --- */
import SplitDiv from '../layouts/SplitDiv';
import { PageContent } from '../components/common/LayoutComponents';
import { AboutText, SectionTitle } from '../components/common/TextComponents';

// Local Functional Components
const TextDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'flex-start',
    margin: theme.spacing(3, 0, 3, 3),
    [theme.breakpoints.down('sm')]: {
        marginLeft: 0
    }
}));

const StyledText = styled(AboutText)(({theme}) => ({
    padding: theme.spacing(1, 0)
}));

const about_texts = ['Hello, I’m Samit. A software engineer based in Melbourne, VIC',
'I enjoy developing and occasionally designing Web & Mobile applications. I pursue to always produce high quality applications that deliver exceptional user experiences',
'Shortly after graduating from the University of Cape Town in Electrical & Computer Engineering, I completed my Masters in Information Technology at RMIT University',
'Besides my work life, I am a religious member/volunteer of the BAPS Swaminarayan Sanastha since 2004. I also occasionally enjoy hiking, photography and learning about watch making'];

const AboutPage = () => {
    const theme = useTheme();

    const AboutIllustration = (
        <Box sx={{width: '100%', textAlign: 'center'}}>
            <AboutSvg style={{ width: '80%', height: 'auto' }}/>
        </Box>
    );

    const TextSection = (
        <TextDiv>
            {about_texts.map((e, i) => <StyledText key={i} children={e}/>)}
        </TextDiv>
    );

    return (
        <PageContent sx={{py: 6, pt: {xs: 3}}}>
            <SectionTitle>About me</SectionTitle>
            <SplitDiv 
                containerStyle={{padding: theme.spacing(4, 0)}}
                firstChild={AboutIllustration}
                secondChild={TextSection}
            />
        </PageContent>
    )
};

export default AboutPage;