export type ExperienceType = {
    role: string,
    company: string,
    period: string,
    tech: Array<string>,
    additional: Array<any>,
    responsibility: Array<string>,
    description: string
};

export const work_data: Array<ExperienceType> = [
    {
        company: 'Five9',
        role: 'Software Engineer',
        period: 'April 2021 - Present',
        tech: [
            'javascript',
            'reactjs',
            'Java Spring'
        ],
        additional: [],
        responsibility: [
            'Development & Maintaining of core applications',
            'Mentoring and aiding with onboarding',
            'Mentor and guide team members with their development work',
        ],
        description: 'Five9 is the leading provider of cloud contact center software. I currently work with the Product Management team to deliver client service requests as well ehancement and maintenance of client applications'
      },
    {
      company: 'Anahana Wellness',
      role: 'Lead Developer/Scrum Master',
      period: 'February 2020 - June 2020',
      tech: [
        'ionic',
        'angular',
        'firebase',
      ],
      additional: [],
      responsibility: [
        'Management of Product Backlog',
        'Defining and allocation of sprint tasks',
        'UX/UI Design',
        'Mentor and guide team members with their development work',
        'Backend + API implementation and integration',
        'Frontend Development'
      ],
      description: 'Anahana Wellness is a Canadian based digital wellness center that provides online wellbeing services. I was involved with Anahana Wellness via a Capstone project with RMIT University.'
    },
    {
      company: 'Jalapeno Digital',
      role: 'Software Engineer',
      period: 'November 2016 - June 2018',
      tech: [
        'angular',
        'ionic',
        'javascript',
        'firebase'
      ],
      additional: [
        { title: 'JQuery', icon: '' },
      ],
      responsibility: [
        'Development of the company services tab from the POC model',
        'Assisting in front-end development of projects using Ionic framework',
        'Backend integration with frontend',
      ],
      description: 'Jalapeno Digital is a start-up digital agency based in South Africa that specialised in providing digital marketing and software solutions. During my time with the company, I was involved in the development of the company site as well as some multi-platform applications'
    }
];