import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { StyleConstants } from '../../constants/StyleConstants';

const { typography, palette } = StyleConstants;

export const PlainText = styled(Typography)(({ theme }) => ({
    color: typography.colors.primary,
}));

export const Text = styled(PlainText)(({ theme }) => ({
    fontSize: theme.spacing(2)
}));

export const SectionTitle = styled(PlainText)(({theme}) => ({
    color: typography.colors.title,
    fontSize: theme.spacing(5),
    fontWeight: typography.fontWeights.bold
}));

export const SectionSubTitle = styled(PlainText)(({theme}) => ({
    color: typography.colors.title,
    fontSize: theme.spacing(3),
    fontWeight: typography.fontWeights.bold
}));

export const HeaderTitle = styled(PlainText)(({theme}) => ({
    fontSize: theme.spacing(2.75),
    color: StyleConstants.palette.light,
}));

export const IntroText = styled(PlainText)(({theme}) => ({
    fontSize: theme.spacing(6),
    fontWeight: typography.fontWeights.light,
    [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(4)
    }
}));

export const IntroTitle = styled(PlainText)(({theme}) => ({
    fontSize: theme.spacing(10),
    fontWeight: typography.fontWeights.bold,
    [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(6)
    }
}));

export const IntroBody = styled(PlainText)(({theme}) => ({
    fontSize: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2.5)
    }
}));

export const AboutText = styled(PlainText)(({theme}) => ({
    fontSize: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2)
    }
}));

export const EmptyText = styled(PlainText)(({theme}) => ({
    color: palette.primary,
    textAlign: 'center'
}));