export const StyleConstants = {
    palette: {
        primary: '#2979FF',
        primary25: '#2979FF25',
        light: '#FFF',
        offwhite: '#F4F4F4'
    },
    boxShadow: '0 0 2px 0 #2979FF25, 0 16px 32px -4px #2979FF25',
    typography: {
        colors: {
            primary: '#404040',
            light: '#fff',
            title: '#2979FF'
        },
        fontWeights: {
            bold: 'bolder',
            light: 'lighter',
            regular: 'regular'
        }
    }
};