import { ButtonBase, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

/* --- Local Imports --- */
import { ExperienceType } from '../../../context/ExperienceContext';
import { Text } from '../../common/TextComponents';
import { StyleConstants } from '../../../constants/StyleConstants';

const { palette } = StyleConstants;

export type TimelineItemType = {
    item: ExperienceType,
    isActive?: boolean,
    onClick?: () => void
};

//Styled elements
const StyledButton = styled(ButtonBase)(({theme}) => ({
    display: 'flex',
    width: '100%',
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2)
}));

const StyledPaper = styled((props) => <Paper variant='outlined' {...props}/>, {
    shouldForwardProp: (prop) => prop !== 'isActive'
})<{isActive?: boolean}>
(({theme, isActive}) => ({
    textAlign: 'left',
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(2),
    flexGrow: 1,
    backgroundColor: isActive ? palette.offwhite : palette.light,
    border: 'none'
}));

const RoleText = styled(Text)({fontWeight: 'bolder'});
const CompanyText = styled(Text)(({theme}) => ({
    color: palette.primary,
    fontSize: theme.spacing(1.75),
    fontWeight: 'bolder'
}));
const PeriodText = styled(Text)(({theme}) => ({
    fontWeight: 'light',
    fontSize: theme.spacing(1.5)
}));

//Render
const TimelineItem = (props: TimelineItemType) => {
    const {item, isActive, onClick} = props;
    const {company, role, period} = item;
    return <StyledButton focusRipple onClick={onClick}>
        <StyledPaper isActive={isActive}>
            <RoleText>{role}</RoleText>
            <CompanyText>{`@${company}`}</CompanyText>
            <PeriodText>{period}</PeriodText>
        </StyledPaper>
    </StyledButton>;
};

export default TimelineItem;