import { styled } from '@mui/material/styles';

/* --- Local Imports --- */
import { StyleConstants } from '../../../constants/StyleConstants';
import { ExperienceType } from '../../../context/ExperienceContext';
import TimelineItem from './TimelineItem';

export type TimelineType = {
    items: Array<ExperienceType>,
    active: number,
    onSelect: (index: number) => void
};

//Styled Elements
const RootContainer = styled('div')(({theme}) => ({
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(1),
        flexDirection: 'column'
    }
}));

const StyledTimeline = styled('div')(({theme}) => ({
    width: theme.spacing(1),
    backgroundColor: StyleConstants.palette.primary25,
    minHeight: theme.spacing(6),
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        height: theme.spacing(1),
        width: '100%',
        minWidth: theme.spacing(6),
        minHeight: theme.spacing(1)
    }
}));

const ItemsContainer = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(-1.25, 0, 0, 0),
        flexDirection: 'row',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    }
}));

const DotItemContainer = styled('div')(({theme}) => ({
    display: 'flex',
    marginLeft: theme.spacing(-1.25),
    [theme.breakpoints.down('sm')]: {
        flex: '0 0 auto',
        flexDirection: 'column',
        marginLeft: 0
    }
}));

const TimelineDot = styled('div')(({theme}) => ({
    margin: theme.spacing(3, 1.5, 0, 0),
    width: theme.spacing(1.585),
    height: theme.spacing(1.5),
    backgroundColor: StyleConstants.palette.primary,
    borderRadius: '100%',
    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 0, 1.5, 3)
    }
}));


const DotItem = (props: any) => {
    const { children } = props;
    return (
        <DotItemContainer>
            <TimelineDot/>
            {children}
        </DotItemContainer>
    );
};

const Timeline = (props: TimelineType) => {
    const { items, active, onSelect } = props;
    return (
        <RootContainer>
            <StyledTimeline/>
            <ItemsContainer>
                {items.map((e, i) => (
                    <DotItem key={i}>
                        <TimelineItem item={e} isActive={Boolean(i === active)} onClick={() => onSelect(i)}/>
                    </DotItem>
                ))}
            </ItemsContainer>
        </RootContainer>
    );
};

Timeline.defaultProps = {
    items: [],
    active: 0,
    onSelect: () => console.log('Timeline [onSelect]')
};

export default Timeline;