import React from 'react';
import { Box, LinearProgress, linearProgressClasses, SxProps, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PlainText, Text } from '../common/TextComponents';
import { StyleConstants } from '../../constants/StyleConstants';

export type ProgressBarType = {
    value: number,
    label?: string | JSX.Element,
    showValue?: boolean,
    styles?: SxProps<Theme>
};

const StyleProgressBar = styled(LinearProgress)(({theme}) => ({
    height: theme.spacing(2),
    borderRadius: theme.spacing(1),
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: theme.spacing(1),
        backgroundColor: StyleConstants.palette.primary,
    },
}));

const ProgressBar = (props: ProgressBarType) => {
    const {value, label, showValue, styles} = props;

    const labelComponent = typeof(label) === 'string' ? <Text>{label}</Text> : label;

    return (
        <Box sx={{ py: 1, ...styles }}>
            {labelComponent}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <StyleProgressBar variant="determinate" value={value}/>
                </Box>
                {showValue && <Box sx={{ minWidth: 35 }}>
                    <PlainText variant="body2" color="text.secondary">{`${Math.round(value)}%`}</PlainText>
                </Box>}
            </Box>
        </Box>
    );
};

export default ProgressBar;