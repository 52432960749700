import React from 'react';
import { AppBar, Box, Toolbar } from "@mui/material";
import { styled } from '@mui/material/styles';

/* --- Local Imports --- */
import { Spacer } from '../components/common/LayoutComponents'
import { Text } from '../components/common/TextComponents';
import { BaseChip } from '../components/data/Chips';
import { StyleConstants } from '../constants/StyleConstants';

/* --- Context Imports --- */
import { skills_map, SkillType } from '../context/SkillsContext';

const { palette } = StyleConstants;

/* --- Interfaces and types --- */
export type FooterBarType = {
    techStack: Array<string>,
    name: string
};

/* --- Styled Elements --- */
const StyledBar = styled(AppBar)(({ theme }) => ({
    background: 'none',
    boxShadow: 'none'
}));

const StyledToolbar = styled(Toolbar)(({theme}) => ({
    padding: theme.spacing(1),
    backgroundColor: palette.primary,
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
    }
}));

const LightText = styled(Text)({color: palette.light})

/* --- Render --- */
const FooterBar = (props: FooterBarType) => {
    const { techStack, name } = props;

    return (
        <StyledBar position='static'>
            <StyledToolbar>
                <LightText>{`Designed & Built by ${name}`}</LightText>
                <Spacer />
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <LightText>Built with</LightText>
                    {techStack.map((e, i) => {
                        const item: SkillType = skills_map[e];
                        return item ? 
                        <BaseChip 
                            key={i}
                            variant='outlined'
                            label={item.label}
                            onClick={() => window.open(item.url, '_blank')}
                            sx={{color: palette.light, mx: 1}}
                        />:
                        <LightText key={i}>{e}</LightText>;
                    })}
                </Box>
            </StyledToolbar>
        </StyledBar>
    )
};

/* --- Component Default Props --- */
FooterBar.defaultProps = {
    tech_stack: [],
    name: ''
};

export default FooterBar;