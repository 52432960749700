import React, { useRef } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import SkillsPage from './pages/SkillsPage';
import ExperiencePage from './pages/ExperiencePage';
import ProjectsPage from './pages/ProjectsPage';
import ContactPage from './pages/ContactPage';
import FooterBar from './layouts/FooterBar';
import { Box, BoxTypeMap } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Muli'
  },
  palette: {
    text: {
      primary: '#404040'
    }
  }
});

const App = () => {
  const aboutRef = useRef<BoxTypeMap>(null);
  const skillsRef = useRef<BoxTypeMap>(null);
  const workRef = useRef<BoxTypeMap>(null);
  const projectsRef = useRef<BoxTypeMap>(null);
  const contactRef = useRef<BoxTypeMap>(null);

  const refList: {[key: string]: any} = {
    'about': aboutRef,
    'skills': skillsRef,
    'work': workRef,
    'projects': projectsRef,
    'contact': contactRef
  };

  const goToSection = (id: string) => {
    if (refList) {
        const lowerId = id.toLowerCase();
        const refItem = refList[lowerId] || null;
        console.log('List', refList, 'item', refItem);
        refItem?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <HomePage goToSection={goToSection}/>
      <Box ref={aboutRef}><AboutPage/></Box>
      <Box ref={skillsRef}><SkillsPage/></Box>
      <Box ref={workRef}><ExperiencePage/></Box>
      <Box ref={projectsRef}><ProjectsPage/></Box>
      <Box ref={contactRef}><ContactPage/></Box>
      <FooterBar techStack={['reactjs']} name='Samit Sharma' />
    </ThemeProvider>
  );
}

export default App;
