import React from 'react';
import { Grid, SxProps, Theme } from '@mui/material';

export type SplitDivType = {
    firstChild: JSX.Element,
    secondChild: JSX.Element,
    containerStyle?: SxProps<Theme> | undefined
};

const SplitDiv = (props: SplitDivType) => {
    const { firstChild, secondChild, containerStyle } = props;

    return (
        <Grid container spacing={3} sx={{ height: '100%', ...containerStyle }}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
                {firstChild}
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
                {secondChild}
            </Grid>
        </Grid>
    );
};

export default SplitDiv;