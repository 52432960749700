import simple_todo_qr from '../assets/qr_codes/qr-code-simple-todo.svg';
import yelp_demo_qr from '../assets/qr_codes/qr-code-yelp-demo.svg';

export type ProjectType = {
  title: string,
  desc: string,
  stack: Array<string>,
  additional_stack: Array<any>,
  url: string,
  qr_img: string | null,
  type: string
};

export const ProjectTypes = {
  Mobile: 'mobile',
  Web: 'web'
};

export const projects_data: Array<ProjectType> = [
  {
    title: 'Google Keep Clone',
    desc: 'A simple clone of the famous Google Keep application. Built using React, React Hooks and Firebase as the backend',
    stack: [
      'reactjs',
      'firebase'
    ],
    additional_stack: [],
    url: 'https://google-keep-clone-samitjsn.netlify.app',
    qr_img: null,
    type: 'web'
  },
  {
    title: 'Restaurant Search',
    desc: 'A simple search application to search for restaurants in your vicinity and view basic information provided the Yelp API',
    stack: [
      'react-native',
    ],
    additional_stack: [
        {title: 'Yelp Fusion', icon: ''}
    ],

    url: 'https://snack.expo.io/@1samit1/yelp-demo-snack',
    qr_img: yelp_demo_qr,
    type: 'mobile'
  },
  {
    title: 'Simple To-do',
    desc: 'A simple todo application to add and monitor current list of tasks. Demonstrates the power of state with Animated',
    stack: [
      'react-native'
    ],
    additional_stack: [],
    url: 'https://snack.expo.io/@1samit1/simple-todo-snack',
    qr_img: simple_todo_qr,
    type: 'mobile'
  },
  {
    title: 'Finding Falcone Problem',
    desc: 'A problem provided by GeekTrust to demonstrate the use of RESTful API with responsive design',
    stack: [
      'reactjs'
    ],
    additional_stack: [
        {title: 'GeekTrust API', icon: ''}
    ],
    url: 'https://samitjsn-feproblem1.netlify.app',
    qr_img: null,
    type: 'web'
  },
];