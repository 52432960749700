import React from 'react';
import { Box, IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

/* --- Local Assets --- */
import {ReactComponent as ContactSvg} from '../assets/svg/contact_illustration.svg';

/* --- Local Imports --- */
import { PageContent } from '../components/common/LayoutComponents';
import { SectionTitle, Text } from '../components/common/TextComponents';
import { StyleConstants } from '../constants/StyleConstants';
import SplitDiv from '../layouts/SplitDiv';
import { contact_data } from '../context/ContactContext';
import { BaseChip } from '../components/data/Chips';

const { palette } = StyleConstants;

// Local Functional Components
const TextDiv = styled('div')(({ theme }) => ({
    margin: theme.spacing(3, 0, 3, 3),
    [theme.breakpoints.down('sm')]: {
        marginLeft: 0
    }
}));

const ContactButton = styled(IconButton)({color: palette.primary});

const ContactPage = () => {
    const theme = useTheme();

    const AboutIllustration = (
        <Box sx={{width: '100%', textAlign: 'center'}}>
            <ContactSvg style={{ width: '60%', height: 'auto' }}/>
        </Box>
    );

    const TextSection = (
        <TextDiv>
            <Text sx={{mb: 1}}>{contact_data.currentText}</Text>
            {contact_data.opportunities.map((e, i) => <BaseChip key={i} label={e.label} variant='outlined' sx={{mr: 0.5, my: 0.25}} />)}
            <Text sx={{mt: 1}}>{contact_data.messageMeText}</Text>
            {contact_data.buttons.map((e) => <ContactButton key={e.id} onClick={() => window.open(e.url, '_blank')}>{e.icon}</ContactButton>)}
        </TextDiv>
    );

    return (
        <div style={{backgroundColor: palette.offwhite}}>
            <PageContent sx={{py: {sm: 10, xs: 3}}}>
                <SectionTitle>Contact</SectionTitle>
                <SplitDiv 
                    containerStyle={{padding: theme.spacing(4, 0)}}
                    firstChild={AboutIllustration}
                    secondChild={TextSection}
                />
            </PageContent>
        </div>
    );
};

export default ContactPage;