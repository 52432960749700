import React from 'react';
import { Box, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';

/* --- Local Imports --- */
import { StyleConstants } from '../constants/StyleConstants';

const { palette } = StyleConstants;

const StyledModal = styled(Modal)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    '*:focus': {
        outline: 'none' 
    }
}));

const ModelBody = styled(Box)(({theme}) => ({
    position: 'relative',
    borderRadius: theme.spacing(2),
    minWidth: '40vw',
    maxWidth: '70vw',
    backgroundColor: palette.light,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
        minWidth: '50vw',
        maxWidth: '80vw',
    },
    [theme.breakpoints.down('sm')]: {
        minWidth: '70vw',
        maxWidth: '95vw',
    }
}));

const ResponsiveModal = (props: any) => {
    const {children, open, onClose} = props;
    return (
        <StyledModal open={open} onClose={onClose}>
            <ModelBody>
                {children}
            </ModelBody>
        </StyledModal>
    );
};

export default ResponsiveModal;