import { Box, Paper, Accordion, AccordionSummary, AccordionDetails, AccordionProps, AccordionSummaryProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExpandMoreOutlined } from '@mui/icons-material';

/* --- Local Imports --- */
import { ExperienceType } from '../../../context/ExperienceContext';
import { skills_map } from '../../../context/SkillsContext';
import { SectionSubTitle, Text, PlainText, EmptyText } from '../../common/TextComponents';
import { StyleConstants } from '../../../constants/StyleConstants';
import { SkillChip } from '../../data/Chips';


/* --- Types/Interfaces/Contstants --- */
const { palette, typography } = StyleConstants;
type TimelineItemDetailType = {
    itemDetails: ExperienceType
};

/* --- Styled Elements --- */
const PaperContainer = styled((props) => <Paper elevation={0} {...props}/>)(({theme}) => ({
    width: `calc(100% - ${theme.spacing(4)})`,
    backgroundColor: palette.offwhite,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
}))
//Styled texts
const RoleText = styled(SectionSubTitle)(({theme}) => ({ 
    color: typography.colors.primary,
    [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2.5)
    }
}));

const CompanyText = styled(PlainText)(({theme}) => ({ 
    color: palette.primary,
    fontWeight: 'bolder'
}));

const PeriodText = styled(Text)(({theme}) => ({
    fontWeight: 'lighter',
    fontSize: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
}));

//Styled Accordion elements
const StyledAccordian = styled((props: AccordionProps) => 
    <Accordion elevation={0} {...props}/>
)
(({theme}) => ({
    backgroundColor: palette.offwhite,
}));

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => 
    <AccordionSummary expandIcon={<ExpandMoreOutlined/>} {...props}/>
)({padding: 0});

/* --- Helper functions --- */
const visitLink = (link?: string) => {
    if (link) {
        window.open(link, '_blank');
    }
};

/* --- Render --- */
const TimelineItemDetail = (props: TimelineItemDetailType) => {
    const { itemDetails } = props;

    if (!itemDetails) {
        return (
            <PaperContainer>
                <EmptyText>No Data to display</EmptyText>
            </PaperContainer>
        );
    };

    return (
        <PaperContainer>
            <RoleText>{itemDetails.role}</RoleText>
            <CompanyText>{`@${itemDetails.company}`}</CompanyText>
            <PeriodText>{itemDetails.period}</PeriodText>
            <PlainText>{itemDetails.description}</PlainText>
            <StyledAccordian>
                <StyledAccordionSummary>
                    <PlainText sx={{fontWeight: '600'}}>Responsibilities</PlainText>
                </StyledAccordionSummary>
                <AccordionDetails>
                    {itemDetails.responsibility.map((e, i) => (
                        <PlainText key={i}>- {e}</PlainText>
                    ))}
                </AccordionDetails>
            </StyledAccordian>
            <PlainText sx={{marginBottom: 1}}>Tech Stack</PlainText>
            <Box sx={{marginBottom: 1}}>
                {itemDetails.tech.map((e, i) => { 
                    const item = skills_map[e] || {label: e};
                    return <SkillChip 
                        key={i} 
                        skill={item}
                        onClick={() => visitLink(item.url)}
                    />
                })}
            </Box>
        </PaperContainer>
    );
};

export default TimelineItemDetail;