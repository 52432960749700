import React, { useState } from 'react';
import { Card, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

/* --- Local Imports --- */
import { PageContent } from '../components/common/LayoutComponents';
import { StyleConstants } from '../constants/StyleConstants';
import { SectionTitle } from '../components/common/TextComponents';
import { work_data } from '../context/ExperienceContext';
import Timeline from '../components/complex/timeline/Timeline';
import TimelineItemDetail from '../components/complex/timeline/TimelineItemDetail';

const StyledCard = styled(Card)(({theme}) => ({
    boxShadow: StyleConstants.boxShadow,
    margin: theme.spacing(2, 0, 4),
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2)
}));

const ExperiencePage = () => {
    const [active, setActive] = useState(0);

    const onSelect = (i: number) => setActive(i);

    const currentDetails = work_data[active];

    return (
        <PageContent sx={{py: {sm: 10, xs: 3}}}>
            <SectionTitle>Work Experience</SectionTitle>
            <StyledCard>
                <Grid container spacing={3}>
                    <Grid item lg={4} sm={5} xs={12}>
                        <Timeline items={work_data} active={active} onSelect={onSelect}/>                        
                    </Grid>
                    <Grid item lg={8} sm={7} xs={12}>
                        <TimelineItemDetail itemDetails={currentDetails}/>
                    </Grid>
                </Grid>
            </StyledCard>
        </PageContent>
    );
};

export default ExperiencePage;