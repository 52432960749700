import react_native from '../assets/svg/skills/react_native.svg';
import ionic_logo from '../assets/svg/skills/ionic_logo.svg';
import angular_logo from '../assets/svg/skills/angular_logo.svg';
import javascript_logo from '../assets/svg/skills/javascript_logo.svg';
import github_logo from '../assets/svg/skills/github.svg';
import nodejs_logo from '../assets/svg/skills/nodejs_logo.svg';
import firebase_logo from '../assets/svg/skills/firebase_logo.svg';
import netlify_logo from '../assets/svg/skills/netlify_logo.svg';
import gcp_logo_alt from '../assets/svg/skills/gcp_logo_alt.svg';
import aws_emr_logo from '../assets/svg/skills/aws_emr_logo.svg';
import python_logo from '../assets/svg/skills/python_logo.svg';
import adobexd_logo from '../assets/svg/skills/adobexd_logo.svg';

export type SkillType = {
    label: string,
    value?: number,
    icon?: string,
    url?: string
};

export const progress_skills: Array<SkillType> = [
    {
        url: 'https://reactjs.org/',
        icon: react_native,
        label: 'ReactJS',
        value: 80
    },
    {
        url: 'https://www.javascript.com/',
        icon: javascript_logo,
        label: 'Javascript',
        value: 85
    },
    {
        url: 'https://reactnative.dev/',
        icon: react_native,
        label: 'React Native',
        value: 70
    },
    {
        url: 'https://ionicframework.com/',
        icon: ionic_logo,
        label: 'Ionic Framework',
        value: 70
    },
    {
        url: 'https://angular.io/',
        icon: angular_logo,
        label: 'Angular',
        value: 70
    },
    {
        url: 'https://firebase.google.com/',
        icon: firebase_logo,
        label: 'Firebase',
        value: 75
    },
];

export const other_skills: Array<SkillType> = [
    {
        url: 'https://github.com/',
        icon: github_logo,
        label: 'Github'
    },
    {
        url: 'https://nodejs.org/',
        icon: nodejs_logo,
        label: 'NodeJS'
    },
    {
        url: 'https://www.netlify.com/',
        icon: netlify_logo,
        label: 'Netlify'
    },
    {
        url: 'https://cloud.google.com/',
        icon: gcp_logo_alt,
        label: 'Google Cloud Platform'
    },
    {
        url: 'https://aws.amazon.com/emr/',
        icon: aws_emr_logo,
        label: 'AWS EMR'
    },
    {
        url: 'https://www.python.org/',
        icon: python_logo,
        label: 'Python'
    },
    {
        url: 'https://www.adobe.com/au/products/xd.html',
        icon: adobexd_logo,
        label: 'Adobe XD'
    }
];

type SkillsMapType = {
    [key: string]: SkillType
}
export const skills_map: SkillsMapType  = {
    'reactjs': {
        url: 'https://reactjs.org/',
        icon: react_native,
        label: 'ReactJS',
    },
    'javascript': {
        url: 'https://www.javascript.com/',
        icon: javascript_logo,
        label: 'Javascript',
    },
    'react-native': {
        url: 'https://reactnative.dev/',
        icon: react_native,
        label: 'React Native',
    },
    'ionic': {
        url: 'https://ionicframework.com/',
        icon: ionic_logo,
        label: 'Ionic Framework',
    },
    'angular': {
        url: 'https://angular.io/',
        icon: angular_logo,
        label: 'Angular',
    },
    'firebase': {
        url: 'https://firebase.google.com/',
        icon: firebase_logo,
        label: 'Firebase',
    },
    'github': {
        url: 'https://github.com/',
        icon: github_logo,
        label: 'Github'
    },
    'nodejs': {
        url: 'https://nodejs.org/',
        icon: nodejs_logo,
        label: 'NodeJS'
    },
    'netlify': {
        url: 'https://www.netlify.com/',
        icon: netlify_logo,
        label: 'Netlify'
    },
    'google-cloud-platform': {
        url: 'https://cloud.google.com/',
        icon: gcp_logo_alt,
        label: 'Google Cloud Platform'
    },
    'aws-emr': {
        url: 'https://aws.amazon.com/emr/',
        icon: aws_emr_logo,
        label: 'AWS EMR'
    },
    'python': {
        url: 'https://www.python.org/',
        icon: python_logo,
        label: 'Python'
    },
    'adobe-xd': {
        url: 'https://www.adobe.com/au/products/xd.html',
        icon: adobexd_logo,
        label: 'Adobe XD'
    }
};