import React from 'react';
import { useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

/* --- Local Imports --- */
import HeaderBar from '../layouts/HeaderBar';
import SplitDiv from '../layouts/SplitDiv';
import DynamicButton, { ButtonTypes } from '../components/buttons/DynamicButton';

/* --- Asset Imports --- */
import { ReactComponent as HomeSvg } from '../assets/svg/home.svg';
import { ReactComponent as BackSvg } from '../assets/svg/backPort.svg'
import { IntroBody, IntroText, IntroTitle } from '../components/common/TextComponents';
import { PageContent } from '../components/common/LayoutComponents';

// Local Imports
const buttonList = [
    { text: 'About', variant: ButtonTypes.Clear },
    { text: 'Skills', variant: ButtonTypes.Clear },
    { text: 'Work', variant: ButtonTypes.Clear },
    { text: 'Projects', variant: ButtonTypes.Clear },
    { text: 'Contact', variant: ButtonTypes.Contained },
];

const textData: any = {
    entrance: 'Hi, my name is',
    name: 'Samit Sharma',
    details: 'Software Engineer based in Melbourne, VIC. Specialising in Full-stack Web/Mobile Development & Data Analysis'
};

// Local Functional Components
const TextDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    justifyContent: 'center'
}));

const BackgroundDiv = styled('div')(({theme}) => ({
    position: 'absolute',
    top: -10,
    left: 0,
    zIndex: -1,
    height: '100vh',
    width: '100vw',
    overflow: 'hidden'
}));

type HomePageProps = {
    goToSection: (id: string) => void
};

// Page Render
const HomePage = (props: HomePageProps) => {
    const { goToSection } = props;
    const theme = useTheme();
    const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const TextSection = (
        <TextDiv>
            <IntroText style={{ color: '#fff' }}>{textData.entrance}</IntroText>
            <IntroTitle style={{ color: '#fff' }}>{textData.name}</IntroTitle>
            <IntroBody style={{ color: '#fff' }}>{textData.details}</IntroBody>
        </TextDiv>
    );

    const HomeIllustration = (
        <TextDiv >
            <HomeSvg style={{ width: '100%', height: 'auto' }}/>
        </TextDiv>
    );

    return (
        <div>
            <HeaderBar title='Samit Sharma' menuList={buttonList} onMenuItemClick={goToSection}>
                {buttonList.map((button, i) => <DynamicButton sx={{fontWeight: 'bold'}} key={i} onClick={() => goToSection(button.text)} variant={button.variant} text={button.text}/>)}
            </HeaderBar>
            <PageContent sx={{ height: '100vh' }}>
                <BackgroundDiv>
                    <BackSvg 
                        style={{
                            width: lgDown ? '200%' : '100%',
                            height: lgDown ? '90%' : '100%'
                        }}
                    />
                </BackgroundDiv>
                <div style={{ height: `calc(100% - ${mobile ? 72 : 80}px)` }}>
                    <SplitDiv 
                        firstChild={TextSection}
                        secondChild={HomeIllustration}
                    />
                </div>
            </PageContent>
        </div>
    );
};

export default HomePage;