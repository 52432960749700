import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StyleConstants } from '../../constants/StyleConstants';

export const ButtonTypes = {
    Outlined: 'outlined',
    Contained: 'contained',
    Clear: 'clear'
};

const BaseButton = styled(Button)(({ theme }) => ({
    color: StyleConstants.palette.primary,
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.25, 3),
    borderRadius: theme.spacing(2),
    boxShadow: 'none'
}));

const ContainerButton = styled(BaseButton)(({ theme }) => ({
    color: '#fff',
    margin: theme.spacing(0, 1),
    backgroundColor: StyleConstants.palette.primary,
    '&:hover': {
        backgroundColor: StyleConstants.palette.primary,
    }
}));

const OutlineButton = styled(BaseButton)(({ theme }) => ({
    margin: theme.spacing(0, 1),
    color: StyleConstants.palette.primary,
    borderColor: StyleConstants.palette.primary
}));

const DynamicButton = (props: any) => {
    const { variant, text } = props;

    switch(variant) {
        case ButtonTypes.Contained:
            return <ContainerButton {...props}>{text}</ContainerButton>;
        case ButtonTypes.Outlined:
            return <OutlineButton {...props}>{text}</OutlineButton>
        case ButtonTypes.Clear:
        default:
            return <BaseButton {...props}>{text}</BaseButton>
    }
};

DynamicButton.defaultProps = {
    variant: 'contained',
    text: 'button',
    onClick: () => console.log('Button clicked!'),
    type: 'button'
};

export default DynamicButton;