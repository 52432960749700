import { Container, ContainerProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Container)(({theme}) => ({
    width: '100vw', 
    overflowX: 'hidden'
}));

export const PageContent = (props: ContainerProps) => <StyledContainer maxWidth='xl' {...props} />;

export const Spacer = () => <div style={{ flexGrow: 1 }}/>;