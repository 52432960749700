import React, { useState } from 'react';
import { Box, AppBar, IconButton, Menu, MenuItem, Toolbar, useMediaQuery, MenuProps } from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import { MenuOutlined } from '@mui/icons-material';
import { HeaderTitle } from '../components/common/TextComponents'
import { Spacer } from '../components/common/LayoutComponents'
import { StyleConstants } from '../constants/StyleConstants';
import { ButtonTypes } from '../components/buttons/DynamicButton';

//Interfaces and types
export type HeaderBarType = {
    children: any,
    title: string,
    menuList: Array<{text: string, variant: string}>,
    onMenuItemClick: (id: string) => void
};

export type MenuItemInterface = {
    variant?: string
};

//Styled Items
const StyledBar = styled(AppBar)(({ theme }) => ({
    margin: theme.spacing(1, 0),
    background: 'none',
    boxShadow: 'none'
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    color: theme.palette.text.primary
}));

const StyledMenuItem = styled(MenuItem, {
    shouldForwardProp: (prop) => prop !== 'variant'
})<MenuItemInterface>(({theme, variant}) => ({
    fontWeight: 600,
    padding: theme.spacing(0, 2),
    color: (variant === ButtonTypes.Contained) ? '#fff' : StyleConstants.palette.primary,
    backgroundColor: (variant === ButtonTypes.Contained) ? StyleConstants.palette.primary : '#fff'
}));

//Render
const HeaderBar = (props: HeaderBarType) => {
    const { children, title, menuList, onMenuItemClick } = props;
    const theme = useTheme();
    const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

    const [menuAnchor, setMenuAnchor] = useState<any>(null);

    const onMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchor(e.currentTarget);
    };

    const onMenuItemClicked = (id: string) => {
        onMenuClose();
        setTimeout(() => onMenuItemClick(id), 300);
    };

    const onMenuClose = () => {
        setMenuAnchor(null);
    };

    const menuProps: MenuProps = {
        open: Boolean(menuAnchor),
        onClose: onMenuClose,
        anchorEl: menuAnchor,
        variant: 'menu',
        anchorOrigin: {vertical: 'bottom', horizontal: 'left'},
        transformOrigin: {vertical: 'top', horizontal: 'left'},
        PaperProps: { sx: {minWidth: 250, borderRadius: theme.spacing(1)} },
        MenuListProps: { sx: {padding: theme.spacing(1, 0, 0)} }
    };

    const menuButton: JSX.Element = (<IconButton size="large" edge="start" children={<MenuOutlined style={{color: '#fff'}}/>} onClick={onMenuClick}/>);
    const menuItemsList: Array<JSX.Element> = menuList.map((e, i) => <StyledMenuItem key={i} variant={e.variant} onClick={() => onMenuItemClicked(e.text)}>{e.text}</StyledMenuItem>)

    return (
        <StyledBar position='static'>
            <StyledToolbar>
                <Box sx={{ display: { md: 'flex', lg: 'none' } }}>
                    {menuButton}
                    <Menu {...menuProps} children={menuItemsList}/>
                </Box>
                <HeaderTitle>{title}</HeaderTitle>
                <Spacer />
                {!lgDown ? children : null}
            </StyledToolbar>
        </StyledBar>
    )
};

HeaderBar.defaultProps = {
    children: null,
    title: 'Header'
};

export default HeaderBar;