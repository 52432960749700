import { Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

/* --- Local Imports --- */
import { StyleConstants } from '../../constants/StyleConstants';
import { Text } from '../common/TextComponents';
import { SkillChip } from '../data/Chips';
import DynamicButton, { ButtonTypes } from '../buttons/DynamicButton';

/* --- Context Imports --- */
import { skills_map } from '../../context/SkillsContext';
import { ProjectTypes, ProjectType } from '../../context/ProjectsContext';


/* --- Local Assets --- */
import mobile_svg from '../../assets/svg/mobile.svg';
import web_svg from '../../assets/svg/web.svg';

const { boxShadow } = StyleConstants;

export type ProjectCardType = {
    project: ProjectType,
    onView: () => void
};

/* --- Styled Elements --- */
const CardContainer = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    backgroundColor: 'transparent',
    boxShadow: boxShadow,
    [theme.breakpoints.down('sm')]: {
        flex: '0 0 75%',
        margin: theme.spacing(0, 2, 0, 0)
    }
}));

export const CardHeader = styled('div')(({theme}) => ({
    display: 'flex',
    margin: theme.spacing(1, 0)
}));

export const CardHeaderImg = styled('img')(({theme}) => ({
    height: theme.spacing(3),
    marginLeft: theme.spacing(2)
}));

export const projectImg = (projectType: string) => (
    projectType === ProjectTypes.Web ? web_svg : projectType === ProjectTypes.Mobile ? mobile_svg : ''
);

/* --- Render --- */
const ProjectCard = (props: ProjectCardType) => {
    const { project, onView } = props;

    const projectType = project.type;

    const projectTechStack = (
        project.stack ? project.stack.map((t, i) => {
            let curStack = skills_map[t] || {label: t, url: ''};
            return <SkillChip key={i} skill={curStack} onClick={()=> window.open(curStack.url, '_blank')}/>
        }) : null
    );

    const buttonText = projectType === ProjectTypes.Mobile ? 'View on Expo' : 'View';

    return (
        <CardContainer>
            <CardHeader>
                <Text sx={{fontWeight: 'bold'}}>{project.title}</Text>
                <CardHeaderImg src={(projectImg(project.type))} alt={projectType}/>
            </CardHeader>
            <Text style={{ whiteSpace: 'pre-line'}}>{project.desc}</Text>
            <Text style={{ marginTop: '16px'}}>Tech Stack</Text>
            {projectTechStack}
            <Box sx={{textAlign: 'center', marginTop: 1}}>
                <DynamicButton variant={ButtonTypes.Clear} onClick={onView} text={buttonText}/>
            </Box>
        </CardContainer>
    );
};

export default ProjectCard;