import { Avatar, Chip, ChipProps, Icon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SkillType } from '../../context/SkillsContext';

/* --- Component Types --- */
type SkillChipType = {
    skill: SkillType,
    onClick: () => void,
    addProps?: ChipProps
};

/* --- Styled Elements --- */
const StyledIcon = styled(Icon)(({theme}) => ({
    textAlign: 'center',
    fontSize: theme.spacing(2.25),
}));

const StyledImg = styled('img')(() => ({
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
}));

const svgIcon = (icon?: string, alt?: string) => {
    const avatarBody = !icon ? alt ? alt.substring(0,1) : 'N/A' : 
    <StyledIcon>
        <StyledImg src={icon} alt={alt}/>
    </StyledIcon>;
    
    return (
        <Avatar sx={{backgroundColor: 'transparent'}}>
            {avatarBody}
        </Avatar>
    );
};

/* --- Main Components --- */
export const BaseChip = styled(Chip)(({theme}) => ({
    fontSize: theme.spacing(2)
}));

export const StyledChip = styled((props: ChipProps) => <BaseChip variant='outlined' {...props}/>)({
    border: 'none'
});

export const SkillChip = (props: SkillChipType) => {
    const { skill, onClick, addProps } = props;
    return (
        <BaseChip
            sx={{mx: 0.5, my: 0.25}} 
            label={skill.label} 
            variant="outlined" 
            avatar={svgIcon(skill.icon, skill.label)}
            onClick={onClick}
            {...addProps}
        />
    );
};

export const ProgressLabelChip = (props: SkillChipType) => {
    const { skill, onClick } = props;
    return (
        <StyledChip 
            sx={{mb: 1}} 
            label={skill.label} 
            avatar={svgIcon(skill.icon, skill.label)}
            onClick={onClick}
        />
    );
};