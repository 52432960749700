import { GitHub, LinkedIn } from '@mui/icons-material';

export type OpportunityType = {
    label: string,
    icon: string
};

export type ContactButtonType = {
    icon: JSX.Element, url: string, id: string
};

type contactData = {
    currentText: string,
    messageMeText: string,
    opportunities: Array<OpportunityType>,
    buttons: Array<ContactButtonType>
};

export const contact_data: contactData = {
    currentText: 'I’m currently not looking for opportunities. However, I specialise in these areas:',
    messageMeText: 'If you would like to contact me about future opportunities or just send a message, connect with me via',
    opportunities: [
      {label: 'Software Engineer', icon: ''},
      {label: 'Full-Stack Web/Mobile Developer', icon: ''},
      {label: 'Data Analyst/Engineer', icon: ''}
    ],
    buttons: [
      {icon: <LinkedIn/>, url: 'https://www.linkedin.com/in/samitjsn/', id: 'linkedin'},
      {icon: <GitHub />, url: 'https://github.com/SamitJsn', id: 'github'},
    ]
};