import React from 'react';
import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/* --- Local Imports --- */
import { PageContent } from '../components/common/LayoutComponents';
import { StyleConstants } from '../constants/StyleConstants';
import { SectionSubTitle, SectionTitle } from '../components/common/TextComponents';
import ProgressBar from '../components/data/ProgressBar';
import {progress_skills, other_skills, SkillType} from '../context/SkillsContext';
import { ProgressLabelChip, SkillChip } from '../components/data/Chips'

const visitLink = (link?: string) => {
    if (link) {
        window.open(link, '_blank');
    }
};
 
const SkillsPage = () => {
    const theme = useTheme();

    const progressLabel = (skill: SkillType) => <ProgressLabelChip skill={skill} onClick={() => visitLink(skill.url)}/>;

    return (
        <div style={{backgroundColor: StyleConstants.palette.offwhite}}>
            <PageContent sx={{ py: 10, pt: {sm: 10, xs: 3} }}>
                <SectionTitle>Skills</SectionTitle>
                <Grid container spacing={3}>
                    {progress_skills.map((e, i) => 
                        <Grid key={i} item lg={6} md={6} xs={12}>
                            <ProgressBar key={i} label={(progressLabel(e) || e.label)} value={e.value || 0}/>
                        </Grid>
                    )}
                </Grid>
                <SectionSubTitle style={{margin: theme.spacing(3, 0 , 1)}}>Other Skills</SectionSubTitle>
                <Box>
                    {other_skills.map((e, i) => 
                        <SkillChip key={i} skill={e} onClick={() => visitLink(e.url)}/>
                    )}
                </Box>
            </PageContent>
        </div>
    );
};

export default SkillsPage;